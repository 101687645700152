import Vue from "vue";
import router from "@/router";
// @ts-ignore
import * as Sentry from "@sentry/vue";
import {isNavigationFailure} from "vue-router";

if (import.meta.env.VITE_SENTRY_DSN) {
  Sentry.init({
    Vue,
    environment: import.meta.env.VITE_ENV,
    release: import.meta.env.VITE_VERSION,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      Sentry.replayIntegration(),
      Sentry.browserTracingIntegration({
        router,
        routeLabel: "path",
        beforeStartSpan: (context: any) => {
          return {
            ...context,
            name: `/#${context.name}`,
          };
        },
      }),
    ],
    tracesSampleRate: Number(import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE ?? 0),
    profilesSampleRate: 0,
    replaysOnErrorSampleRate: Number(import.meta.env.VITE_SENTRY_REPLAY_ERROR_SAMPLE_RATE ?? 0),
    replaysSessionSampleRate: 0,
    autoSessionTracking: true,
    tracePropagationTargets: [import.meta.env.VITE_BACKEND_URL],
    ignoreErrors: ["AxiosError"],
    async beforeSend(event, hint) {
      if (isNavigationFailure(hint.originalException)) return null;

      return event;
    },
  });
}
