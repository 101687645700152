import axios, {AxiosInstance, InternalAxiosRequestConfig} from "axios";
import {camelizeKeys, decamelizeKeys} from "humps";
import Vue from "vue";
import router from "@/router";
import {useAppStore} from "@/stores/app";
import {DateTime} from "luxon";

let csrfToken: string;

export enum ErrorMode {
  NONE,
  NOTIFY,
  SET_ERROR,
}

const api: AxiosInstance = axios.create({
  baseURL: import.meta.env.VITE_BACKEND_URL ?? "http://localhost:5000",
  timeout: 10000,
  withCredentials: true,
});

api.interceptors.request.use(
  function (config: InternalAxiosRequestConfig) {
    if (["post", "delete", "patch", "put"].includes(config.method!)) {
      if (csrfToken !== "") {
        config.headers["X-CSRFToken"] = csrfToken;
      }
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  },
);

// https://medium.com/javascript-in-plain-english/configuring-a-camelcase-to-snake-case-parser-with-axios-9fa34fd3b16f
api.interceptors.request.use((config) => {
  const newConfig = {...config};
  const urlParams = decamelizeKeys(
    Object.fromEntries(new URLSearchParams(config.url!.split("?")[1])),
  );
  // @ts-ignore
  const paramsStr = "?" + new URLSearchParams(urlParams).toString();
  newConfig.url = config.url!.split("?")[0] + (paramsStr.length > 1 ? paramsStr : "");

  if (newConfig.headers?.["Content-Type"] === "multipart/form-data") return newConfig;
  if (config.params) {
    newConfig.params = decamelizeKeys(config.params);
  }
  if (config.data) {
    // noinspection TypeScriptValidateTypes
    newConfig.data = decamelizeKeys(config.data);
  }
  return newConfig;
});

api.interceptors.response.use(
  (response) => {
    if (response.data && response.headers?.["content-type"] === "application/json") {
      response.data = camelizeKeys(response.data);
    }
    return response;
  },
  (error) => {
    const response = error.response;
    if (response) {
      if (response.data && response.headers?.["content-type"] === "application/json") {
        response.data = camelizeKeys(response.data);
      }
    }
    return Promise.reject(error);
  },
);

api.interceptors.response.use((response) => {
  const token = response.headers["x-csrftoken"] ?? "";
  if (token !== "") {
    csrfToken = token;
  }
  return response;
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    const response = error.response;
    console.error(response ?? error);

    if (!response) {
      Vue.prototype.$notify({
        type: "error",
        title: "Error",
        text: "Request failed!",
      });
      return Promise.reject(error);
    }

    let errorMode = response.config.errorMode === null ? ErrorMode.NONE : response.config.errorMode;
    if (!response || errorMode === false) return Promise.reject(error);

    let errorData = null;
    if (response.config.method.toUpperCase() === "GET") {
      errorMode = errorMode ?? ErrorMode.SET_ERROR;
      switch (response.status) {
        case 401:
          if (router.currentRoute.name !== "login") router.replace({name: "login"});
          break;
        case 403:
          errorData = {
            title: "Forbidden",
            message: response.data.message ?? "You do not have permission to access this resource.",
          };
          break;
        case 404:
          errorData = {
            title: "Not Found",
            message: "The requested object could not be loaded.",
          };
          break;
        default:
          if (response.status >= 500) {
            errorData = {
              title: "Server Error",
              message: "The request failed due to a server error.\nPlease try again later.",
            };
          }
          break;
      }
    } else {
      errorMode = errorMode ?? ErrorMode.NOTIFY;
      errorData = {
        title: "Error",
        message: response.data?.message ?? "An unexpected error occurred while saving!",
      };
    }

    if (errorData) {
      if (errorMode === ErrorMode.NOTIFY) {
        Vue.prototype.$notify({
          type: "error",
          title: errorData.title,
          text: errorData.message,
        });
      } else if (errorMode ?? ErrorMode.SET_ERROR === ErrorMode.SET_ERROR) {
        const store = useAppStore();
        store.setError({isDismissible: response.config.errorDismissible, ...errorData});
      }
    }

    return Promise.reject(error);
  },
);

api.interceptors.response.use((response) => {
  function isIsoDateString(value: any): boolean {
    const isoDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?(?:[-+]\d{2}:?\d{2}|Z)?$/;
    return value && typeof value === "string" && isoDateFormat.test(value);
  }

  function handleDates(body: any) {
    if (body === null || body === undefined || typeof body !== "object") return body;

    for (const key of Object.keys(body)) {
      const value = body[key];
      if (isIsoDateString(value)) body[key] = DateTime.fromISO(value);
      else if (typeof value === "object") handleDates(value);
    }
  }

  handleDates(response.data);
  return response;
});

export default api;
