import {useAppStore} from "@/stores/app";
import {NavigationGuardNext, Route} from "vue-router";

export const authGuard = () => (to: Route, from: Route, next: NavigationGuardNext) => {
  if (to.meta?.isPublic) return next();

  const store = useAppStore();
  if (store.user === null) return next({name: "login", query: {next: to.path}});

  // @ts-ignore: idk why tf this is necessary
  if (!("hasRoles" in to.meta) || to.meta?.hasRoles(store.user)) return next();

  return next({name: "403"});
};

export const modeGuard = () => async (to: Route, from: Route, next: NavigationGuardNext) => {
  const store = useAppStore();
  if (
    typeof to.params.mode !== "undefined" &&
    to.params.mode !== "lc" &&
    to.params.mode !== "stats"
  ) {
    store.setError({title: "404", message: "Invalid URL."});
    return false;
  }

  if (!store.isLC && (to.params.mode === "lc" || to.path.startsWith("/lc/"))) {
    await store.setLCMode(true);
    // checking if not in lc after setting mode in case prompt denied, no roles, etc
    // if mode didn't get set, redirect to stats
    if (!store.isLC) {
      await store.setLCMode(false);
      next({
        name: to.name!,
        params: {...to.params, mode: "stats"},
      });
    }
  }

  return next();
};
